import {useEffect, useState} from "react";

const PerfumeImg = ({perfumeImg, perfumeNotes}) => {
    let orderNumber = 1;
    const [blendImageUrls, setBlendImageUrls] = useState([]);
    const [blendNames, setBlendNames] = useState([]);

    useEffect(() => {
        async function getBlends() {
            try {
                const response = await fetch(
                    'https://no-ordinary-scent-api.herokuapp.com/v1/get-all-accord-index'
                );
                return response.json();
            } catch (e) {
                // console.error(e);
            }
            return null;
        }

        getBlends().then((data) => {
            const blends = Object.assign(data.base, data.accord);
            try {
                setBlendImageUrls(perfumeNotes.map(blend => blends[blend].imageFile[0].thumbnails.large.url))
                setBlendNames(perfumeNotes.map(blend => blends[blend].blendName))
            }
            catch (e) {
               console.error(e)
            }
        });
    }, []);

    function imageCB(imgUrl, index) {
        return <div key={imgUrl}>
            {perfumeImg.length ? <p>Image 0.{orderNumber++}</p> : <span>{blendNames[index]}</span>}
            <div key={imgUrl} className="img-container">
                <img src={imgUrl} className="perfume-img" alt=""/>
            </div>
        </div>
    }


    return (
        <>
            <div className="image-text-div">
                <h1 className="header-h1">YOUR {perfumeImg.length ? "MEMORIES" : "BLENDS"}</h1>
                {perfumeImg.length ?
                    <p className="image-p">The NOS Emotiontech&trade; Artificial intelligence has analysed the following
                        Images to create a scent just for you.</p> :
                    <p className="image-p">These are the blends you have chosen.</p>}
            </div>
            <div className="perfume-img-wrapper">
                {
                    perfumeImg.length > 0
                        ? perfumeImg
                            .map(imageCB)
                        : blendImageUrls.map(imageCB)
                }
            </div>
        </>
    )
};

export default PerfumeImg;
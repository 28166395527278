import "./kimchii.scss";
import PerfumeImg from "../../components/PerfumeImg";

const KimchiiCustomPage = () => {
    return (
        <>
            <div className="kimchii-header">
                <div className="kimchii-title">
                    <span className="neon">
                        Kimchii x NOS @ Soho House
                    </span>
                </div>
                <div className="kimchii-gradient">
                </div>
            </div>

            <div className="image-row">
                <div className="image-container">
                    <img src={require("./KIMCHII_LOGO_BLACK.png")} alt="Kimchii logo"/>
                </div>
                <div className="image-container">
                    <img src={require("./NOS_EMOTIONTECH.png")} alt="NOS Emotiontech logo"/>
                </div>
                <div className="image-container">
                    <img src={require("./SH logo Stockholm (002).png")} alt="SOHO House logo"/>
                </div>
            </div>
            <div>
                <p className="header-h3">Hello,</p>
                <h1 className="header-h1">HERE'S YOUR PERSONAL SCENT!</h1>
                <div className="parfume-info">
                    <div className="perfume-info-container">
                        <div className="perfume-scentnotes-container">
                            <div className="perfume-scentnotes">
                                <p className="perfume-scentnotes-p">Top notes</p>
                                <p className="perfume-scentnotes-h3">Green Tea, Bergamot, Gardenia</p>
                            </div>
                            <div className="perfume-scentnotes">
                                <p className="perfume-scentnotes-p">Heart notes</p>
                                <p className="perfume-scentnotes-h3">Bamboo, Soft Patchouli</p>
                            </div>
                            <div className="perfume-scentnotes">
                                <p className="perfume-scentnotes-p">Base notes</p>
                                <p className="perfume-scentnotes-h3">Amber Extreme, Moss, Sheer Patchouli</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="kimchii-tag-heading">TAGS</h3>
                <div className="kimchii-tags-container">
                    <span className="kimchii-tag">CURIOUS</span>
                    <span className="kimchii-tag">EDGY</span>
                    <span className="kimchii-tag">LIGHTNESS</span>
                    <span className="kimchii-tag">HAPPINESS</span>
                </div>
                <br />
                <br />
                <br />
                <PerfumeImg perfumeImg={[require("./IMG_5793.jpeg"), require("./Opt17.jpeg"), require("./bouol_synk11.jpeg")]} perfumeNotes={['B7, A13']}/>
                <div className="kimchii-footer">
                    <div className="kimchii-footer-gradient"/>
                    <div className="footer">
                        <h1>ABOUT YOUR SCENT</h1>
                        <p>
                            This scent is made just for you, freshly blended in the lab in Stockholm, Sweden.
                            Since it’s so fresh, the materials need some time to fully blend and become balanced.</p>
                        <br />
                        <p>At first you might experience the scent a bit sharp because of the alcohol. Ideally, let the scent rest for 14 days, dry and dark before frequent use. This process is called maceration.
                            Store perfume away from heat, humidity and sunlight to keep it fresh for as long as possible.</p>
                        <br />
                        <p> Enjoy and thank you for supporting NOS Emotiontech&trade;</p>
                        <br />
                        <p>For ordering full size 30ml EDP (1.250SEK) please email <a className="footer-link" href="mailto: hello@nosemotiontech.com">hello@nosemotiontech.com</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KimchiiCustomPage;
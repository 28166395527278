const NotFound404 = () => {

    return (
        <>
        <div className="notFound-wrapper">
            <h1>404</h1>
            <p className="footer-p">Sorry, we couldn't find the page you're looking for.</p>
            <a href="https://nosworkshop.se/" className="btn">GO TO HOMEPAGE</a>
        </div>
        </>
    )
};

export default NotFound404;
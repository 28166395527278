import param from 'jquery-param';

const Footer = ({perfumeName, perfumeMadeBy, recipe, qrAccess, images, orderNr}) => {
    const productVariantId = 31340782977103;
      const createReOrderUrl = () => {
        let paramObject = {
            id: productVariantId,
            quantity: 1,
            properties: {
              _recipe: recipe + ' - Re-order',
              'Perfume name': perfumeName,
              'Made by': perfumeMadeBy,
              _QRaccess: qrAccess,
              _oldOrderNr: orderNr
            }
          };
          [...images].forEach((image, index) => {
            paramObject.properties[`_image ${index + 1}`] = image;
          });
          const queryString = param(paramObject);
          return `https://nosworkshop.se/cart/add?${queryString}`;
      }

    return (
        <>
        <div className="footer">
            <h1>ABOUT YOUR SCENT</h1>
             <p className="footer-p">
                This scent is made just for you, freshly blended in the lab in Stockholm, Sweden.
                Since it’s so fresh, the materials need some time to fully blend and become balanced.</p>
            <p className="footer-p-mid">At first you might experience the scent a bit sharp because of the alcohol. Ideally, let the scent rest for 14 days, dry and dark before frequent use. This process is called maceration.
                Store perfume away from heat, humidity and sunlight to keep it fresh for as long as possible.</p>
            <p className="footer-p-end"> Enjoy and thank you for supporting NOS Emotiontech&trade;</p>
{/*            <div className="footer-bttns">
                <a
                    href={createReOrderUrl()}
                    className="btn light">
                    RE-ORDER SCENT
                </a>
                <p className="footer-bttns-p">or</p>
                <a
                href="https://create.noordinaryscent.com/"
                className="btn">
                    CREATE NEW SCENT
                </a>
            </div>*/}
        </div>
        </>
    )
};

export default Footer;

const Header = ({logo}) => {

    return (
        <>
            <div className="header-logo-wrapper">
                <a href="https://nosworkshop.se/">
                    <img src={logo} className="header-logo" alt="logo"/>
                </a>
            </div>
        </>
    )
};

export default Header; 